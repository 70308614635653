body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Sidebar */
.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #343a40;
  transition: transform 0.3s ease;
  z-index: 1000;
}

/* Sidebar is visible by default on large screens */
.sidebar.open,
.sidebar {
  transform: translateX(0);
}

/* Sidebar is hidden on small screens unless opened */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-250px);
  }

  .sidebar.open {
    transform: translateX(0);
  }
}

/* Hamburger Menu (visible only on small screens) */
.hamburger-menu {
  display: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  background-color: #343a40;
}

/* Show Hamburger on Small Screens */
@media (max-width: 768px) {
  .hamburger-menu {
    display: block;
  }
}
