.main-content {
    height: 100vh;
    overflow-y: auto;
  }
  
  .header {
    height: 60px;
  }
  
  .content {
    color: white;
  }
  
  .bg-dark {
    background-color: #212529 !important;
  }
  
  .bg-secondary {
    background-color: #343a40 !important;
  }