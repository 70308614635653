.sidebar {
  height: 100vh;
  padding-top: 20px;
}

.sidebar .nav-link {
  font-size: 18px;
  padding: 15px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px;
}

.sidebar .nav-link:hover {
  background-color: #343a40;
}

.menu-icon {
  margin-right: 10px;
}